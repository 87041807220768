import { loadScript } from '@/Utils/ScriptUtility';
import { ExternalSignInErrorTypes } from '@/Types/ExternalSignInErrorTypes';
import { ExternalSignInError } from '@/Utils/ExternalSignInUtility';
import { userIdentityProviders, externalScripts } from '@/Config';
import Log from '@/Utils/Log';
import Resources from '@/Resources.js';

const facebookSignInUrl = externalScripts.facebookSignInUrl;
const appId = userIdentityProviders.facebook.appID;

const apiOptions = {
    scope: 'email',
    return_scopes: true,
    // Re-authentication when the user has refused to share the data
    auth_type: 'rerequest',
};

export default class FacebookSignIn {
    async initAsync() {
        try {
            const result = await loadScript(facebookSignInUrl);

            if (result) {
                this.loadFacebookApi();
                return this;
            }
        }
        catch (error) {
            throw new Error(error);
        }
    }

    loadFacebookApi () {
        window.FB.init({
            appId,
            xfbml: false,
            version: 'v18.0',
            cookie: true,
            status: true,
        });
    }

    signInAsync() {
        return new Promise((resolve, reject) => {
            try {
                window.FB.login(response => {
                    if (response.authResponse?.grantedScopes.includes('email') && response.status === 'connected') {
                        resolve(response.authResponse.accessToken);
                    }
                    else if (!response.authResponse?.grantedScopes.includes('email') && response.status === 'connected') {
                        reject(new ExternalSignInError(
                            ExternalSignInErrorTypes.permissionDenied,
                            Resources.Account.ExternalSignInFacebookPermissionDeniedErrorMessage));
                    }
                    else {
                        reject(new ExternalSignInError(ExternalSignInErrorTypes.popupClosed));

                    }
                }, apiOptions);
            }
            catch (error) {
                // Throwing error to rollbar
                Log.error(error);
                reject(new ExternalSignInError(ExternalSignInErrorTypes.error, 'External script error.'));
            }
        });
    }

}
