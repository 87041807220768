import '@adyen/adyen-web/dist/adyen.css';
const getAdyenCheckout = () => import(/* webpackChunkName: 'adyen-web' */  '@adyen/adyen-web');
import { adyenConfig } from '@/Config';

import Config from '@/Config';
const defaultLanguage = 'en-US';

const { clientKey, useLiveMode } = adyenConfig;

export const getFormBuilder = async (onAdditionalDetails, onError, onBinLookup) => {
    const language = Config?.language?.currentLanguageIsoCode || defaultLanguage;
    const adyenCheckout = (await getAdyenCheckout()).default;

    const adyenCheckoutInstance = await adyenCheckout({
        locale: language,
        environment: useLiveMode ? 'live' : 'test',
        clientKey,
        onAdditionalDetails,
        onError,
        onBinLookup,
    });

    return adyenCheckoutInstance;
};

export const threeDSConfiguration = {
    size: '05', // ['100%', '100%'] - The default window size
};