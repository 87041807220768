var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_py-3 _bdb--thick _bc--disabled" },
    [
      _c(
        "div",
        { staticClass: "_flex _gap-3 _pos-r" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.to, title: _vm.name } },
            [
              _vm.badges.length > 0
                ? _c(
                    "div",
                    { staticClass: "gfu-labels _ml-2" },
                    _vm._l(_vm.badges, function (badge, index) {
                      return _c("base-badge", { key: index }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(badge) +
                            "\n                "
                        ),
                      ])
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("base-image", {
                staticClass: "_rounded--common",
                attrs: {
                  src: _vm.imageUrl,
                  alt: _vm.name,
                  loading: "lazy",
                  width: "120",
                  height: "120",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "h4",
              {
                class: ["gfu-hd", _vm.headerClasses],
                attrs: { "data-qa": "cart-table:ProductName" },
              },
              [
                _c("router-link", { attrs: { to: _vm.to, title: _vm.name } }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["gfu-hd", _vm.headerClasses],
                attrs: { "data-qa": "cart-table:ProductPrice" },
              },
              [_vm._v(_vm._s(_vm.priceFormatted))]
            ),
            _vm._v(" "),
            _vm.handleTax
              ? _c(
                  "div",
                  {
                    class: ["gfu-bt", _vm.descriptionClasses, "_tc--light"],
                    attrs: { "data-qa": "cart-table:ProductTax" },
                  },
                  [
                    _c(
                      "span",
                      [
                        _c(
                          "localize",
                          {
                            key: _vm.taxFormatted,
                            attrs: {
                              resource:
                                _vm.Resources.YourPledge
                                  .CartTableOrderItemTaxValueLabel,
                            },
                          },
                          [
                            _c("span", { attrs: { "format-key": "0" } }, [
                              _vm._v(_vm._s(_vm.taxFormatted)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["gfu-bt", _vm.descriptionClasses, "_tc--light"],
                attrs: { "data-qa": "cart-table:ProductQuantity" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.Resources.YourPledge.CartTableQuantityHeader) +
                    ": " +
                    _vm._s(_vm.quantity) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["gfu-bt", _vm.descriptionClasses, "_tc--light"],
                attrs: { "data-qa": "cart-table:ProductOptions" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.optionsFormatted) +
                    "\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.hasActions
            ? _c("balloon-actions", {
                class: ["_right-0", "_top-a", _vm.balloonActionClasses],
                attrs: {
                  actions: _vm.balloonActions,
                  mode: _vm.balloonActionMode,
                  theme: "white",
                  "data-qa": "cart-table:EditItem",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isErrorVisible
        ? _c(
            "div",
            {
              staticClass: "_tc--error _mt-3",
              attrs: {
                "data-qa": "cart-table-item-row-quantity-error:WarningIcon",
              },
            },
            [
              _c("base-icon", { attrs: { name: "times-circle" } }),
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.subitemsCounter > 0
        ? _c(
            "div",
            {
              staticClass: "_mt-3",
              attrs: { "data-qa": "cart-table:SubitemsCount" },
            },
            [
              _vm.isSubitemsExpanded
                ? _c(
                    "a",
                    {
                      staticClass: "_tc--accent",
                      attrs: { href: "#", "data-qa": "subitems-expanded" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onToggleSubitems.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("base-icon", { attrs: { name: "chevron-up" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.YourPledge
                                    .CartTableOrderItemHideSetItemsLabel,
                              },
                            },
                            [
                              _c("span", { attrs: { "format-key": "0" } }, [
                                _vm._v(_vm._s(_vm.pluralizedSetItemsCount)),
                              ]),
                              _vm._v(" "),
                              _c("span", { attrs: { "format-key": "1" } }, [
                                _vm._v(_vm._s(_vm.subitemsCounter)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "a",
                    {
                      staticClass: "_tc--accent",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onToggleSubitems.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("base-icon", { attrs: { name: "chevron-down" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.YourPledge
                                    .CartTableOrderItemShowSetItemsLabel,
                              },
                            },
                            [
                              _c("span", { attrs: { "format-key": "0" } }, [
                                _vm._v(_vm._s(_vm.pluralizedSetItemsCount)),
                              ]),
                              _vm._v(" "),
                              _c("span", { attrs: { "format-key": "1" } }, [
                                _vm._v(_vm._s(_vm.subitemsCounter)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSubitemsExpanded
        ? _c(
            "div",
            {
              class: [
                "_mt-3",
                "_grid",
                "_grid-cols-1",
                "_grid-cols-2--m",
                "_fd-r",
                _vm.subitemBoxClassList,
              ],
            },
            _vm._l(_vm.subitems, function (subitem, index) {
              return _c(
                "cart-table-product-order-subitem",
                _vm._b(
                  { key: `subitem.name-${index}` },
                  "cart-table-product-order-subitem",
                  subitem,
                  false
                )
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOptionsModalVisible
        ? _c(
            "order-item-modal",
            _vm._b(
              {
                attrs: { editing: true },
                on: {
                  submit: _vm.onEdit,
                  close: _vm.closeOptionsSelectionModal,
                },
              },
              "order-item-modal",
              _vm.editOrderItemModalModel,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }