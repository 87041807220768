export default {
    props: {
        to: { type: [String, Object, Array], default: null },
        tag: { type: String, default: 'div' },
        append: { type: Boolean, default: false },
        exact: { type: Boolean, default: false },
        activeClass: { type: String, default: 'router-link-active' },
        exactActiveClass: { type: String, default: 'router-link-exact-active' },
    },
    render(createElement) {
        const router = this.$router;
        const current = this.$route;

        const classes = {
            [this.activeClass]: false,
            [this.exactActiveClass]: false,
        };

        if (this.to) {
            const { route } = router.resolve(this.to, current, this.append);
            classes[this.exactActiveClass] = route.fullPath === current.fullPath;
            classes[this.activeClass] = this.exact ? true : current.fullPath.startsWith(route.fullPath);
        }

        const data = {
            class: classes,
            on: this.$listeners,
        };

        return createElement(this.tag, data, this.$slots.default);
    },
};