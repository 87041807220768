var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-label--light _mt-1 _flex _ac-c _jc-c _tac _px-1 _py-1 _fa--sm _rounded--common",
    },
    [
      _c(
        "localize",
        {
          key: _vm.amount,
          attrs: {
            resource:
              _vm.Resources.Checkout.CheckoutInstallmentsDetailsInfoLabel,
          },
        },
        [
          _c("span", { attrs: { "format-key": "0" } }, [
            _vm._v(_vm._s(_vm.installmentsCount)),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "_fw-b _whs-nw", attrs: { "format-key": "1" } },
            [_vm._v(_vm._s(_vm.amount))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }