import '@/Utils/CustomValidators';

import { mapState, mapActions, mapStores } from 'pinia';
import { useCartSummaryStore } from '@/Stores/Web/cartSummaryStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { useUserContextStore } from '@/Stores/Web/userContextStore';
import { useRootStore } from '@/Stores/Web/rootStore';
import ValidatorHelpersMixin from '@/Mixins/ValidatorHelpers';
import NotificatorsMixin from '@/Mixins/Notificators';
import AsyncHelpersMixin from '@/Mixins/AsyncHelpers';

export default {
    mixins: [ValidatorHelpersMixin, AsyncHelpersMixin, NotificatorsMixin],
    methods: {
        wrapApiAction(apiRequestPromise, fieldNamePrefix = '', preventHandleError = false) {
            return apiRequestPromise
                .then((response) => {
                    if (!response.data) {
                        return Promise.reject(Error('Invalid Api response.'));
                    }

                    return this.handleApiActionResult(response.data, fieldNamePrefix, preventHandleError);
                })
                .catch(error => {
                    if (error && error.response && error.response.data) {
                        return this.handleApiActionResult(error.response.data, fieldNamePrefix, preventHandleError);
                    }

                    return Promise.reject(error);
                });
        },
        handleApiActionResult(result, fieldNamePrefix = '', preventHandleError = false) {
            if (!result.success && !result.isSuccess) {
                const unhandledFields = [];
                let responseHasErrors = false;

                if (result.fields?.length > 0) {
                    responseHasErrors = true;
                    for (const field of result.fields) {
                        if (!this.assignErrorToField(fieldNamePrefix, field.fieldName, field.message)) {
                            unhandledFields.push(field.fieldName);
                        }
                    }
                }
                else if (result.data?.errors && Object.keys(result.data.errors).length !== 0) {
                    responseHasErrors = true;
                    for (const key in result.data.errors) {
                        // only use first error because we don't support multiple errors on fields
                        const firstError = result.data.errors[key][0];
                        if (!this.assignErrorToField(fieldNamePrefix, key, firstError)) {
                            unhandledFields.push(key);
                        }
                    }
                }
                if (responseHasErrors) {
                    if (unhandledFields.length > 0) {
                        throw Error(`Invalid value in following field(s): ${unhandledFields.join(', ')}`);
                    }

                    return !preventHandleError ? Promise.reject(Error('Invalid value in one of the fields.')) : Promise.reject();
                }

                return Promise.reject(result.message || 'Server action has failed. Please retry and let us know if the problem persists.');
            }

            return Promise.resolve(result);
        },
        assignErrorToField(fieldNamePrefix, fieldName, fieldErrorMessage) {
            const key = fieldNamePrefix + fieldName;
            const valField = this.$validator.fields.find({ name: key });

            if (valField) {
                this.$validator.errors.add({
                    field: key,
                    msg: fieldErrorMessage || 'Field value is invalid.',
                    id: valField ? valField.id : null,
                });

                return true;
            }

            return false;
        },
        ensureStoreInitialized() {
            // Deprecated promise, should be cleaned up.
            return Promise.all([this.rootStore.initialize()]);
        },
        ...mapActions(useCartSummaryStore, ['updateCartSummary']),
        ...mapActions(useProjectContextStore, ['updateProjectContext']),
    },
    computed: {
        errors() {
            return this.$validator.errors;
        },
        userContext() {
            return this.userContextStore.userContext;
        },
        // This is also used on admin page, should be moved from this place.
        ...mapState(useProjectContextStore, ['projectContext']),
        ...mapStores(useUserContextStore),
        ...mapStores(useRootStore),
    },
    beforeMount() {
        this.initializeValidator();
    },
};
