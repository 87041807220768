import VueRouter from 'vue-router';

const location = window.location;
const history = window.history;
const hash = location.hash;

const routes = [];
const savedScrollPositions = {};

// Redirect to admin page if hash starts with #/admin
if (hash.startsWith('#/admin')) {
    location.href = hash.replace('#', '');
}

const router = new VueRouter({
    routes,
    mode: 'abstract',
    scrollBehavior(to, from, savedPosition) {
        if (to.matched.some((record) => record.meta.noScroll) || to.query.focusFields) {
            return null;
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
});

function getCurrentHashPath() {
    const path = location.hash.replace('#', '');
    return path.length > 0 ? path : null;
}

function registerHashWatchToRouter(router) {
    if (location.hash) {
        router.push(getCurrentHashPath(), () => {});
    }

    window.addEventListener('hashchange', handleHashChange(router), false);
    window.addEventListener('popstate', handlePopstate(router), false);
}

function handleHashChange(router) {
    return () => {
        const isWeb = router.app.$displayMode.isWeb;
        const isPWA = router.app.$displayMode.isPWA;
        const hashPath = getCurrentHashPath();
        const routerPath = router.currentRoute.fullPath;
        const isHashPathEmpty = hashPath !== routerPath && history.state === null;

        if (isHashPathEmpty && isWeb) {
            router.push(hashPath ? hashPath : '/', () => {});
        }
        else if (isHashPathEmpty && isPWA) {
            router.replace(hashPath ? hashPath : '/', () => {});
        }
    };
}

function handlePopstate(router) {
    return () => {
        const isWeb = router.app.$displayMode.isWeb;
        const hashPath = getCurrentHashPath();
        const routerPath = router.currentRoute.fullPath;
        if (hashPath !== routerPath && history.state !== null && isWeb) {
            router.replace(hashPath ? hashPath : '/', () => {});
        }
    };
}

function handleBeforeEach(router) {
    return (to, from, next) => {
        if (savedScrollPositions[to.fullPath] && from?.meta && !from.meta.noScroll) {
            window.scrollTo(0, savedScrollPositions[to.fullPath].y);
        }
        router.previousRoute = from;
        next();
    };
}

function handleAfterEach(router) {
    return (to, from) => {
        // Store scroll position if necessary
        const isWeb = router.app?.$displayMode?.isWeb;
        if (from?.meta?.noScroll !== true && from?.name) {
            savedScrollPositions[from.fullPath] = { y: window.pageYOffset };
        }

        if (to && isWeb) {
            const hashPath = getCurrentHashPath();
            const pathnameWithSearch = `${location.pathname}${location.search}`;
            const fromFullPath = from?.fullPath;
            const toFullPath = to?.fullPath;

            if (hashPath !== toFullPath) {
                const state = { from: fromFullPath, to: toFullPath };

                if (to.name === null) {
                    history.pushState(state, null, pathnameWithSearch);
                }
                else {
                    const fullPathWithHash = `${pathnameWithSearch}#${toFullPath}`;
                    const params = [state, null, fullPathWithHash];

                    // Handle router state update (push or replace)
                    if (router.previousRouteReplaced) {
                        history.replaceState(...params);
                    }
                    else {
                        history.pushState(...params);
                    }
                }

                // Reset flag after route change
                router.previousRouteReplaced = false;
            }
        }
    };
}

const extendWithHybridCapabilities = (router) => {
    router.previousRouteReplaced = false;
    router.previousRoute = null;
    router.originalResolve = router.resolve;
    router.originalReplace = router.replace;

    // NOTE: hack for abstract+hash mode hybrid
    router.resolve = function () {
        const originalResult = router.originalResolve.apply(router, arguments);
        originalResult.href = `#${originalResult.href}`;
        return originalResult;
    };

    router.replace = function () {
        router.previousRouteReplaced = true;
        return router.originalReplace.apply(router, arguments);
    };

    router.beforeEach(handleBeforeEach(router));
    router.afterEach(handleAfterEach(router));

    registerHashWatchToRouter(router);

    return router;
};
export default extendWithHybridCapabilities(router);
