var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c(
        "div",
        {
          staticClass:
            "gfu-grid__cell gfu-4of10--xl gfu-left-3of10--xl gfu-1of2--l gfu-left-1of4--l gfu-4of6--m gfu-left-1of6--m gfu-1of1",
        },
        [
          _c(
            "div",
            {
              staticClass: "gfu-box gfu-box--thick _mt-6 _mb-3",
              attrs: { "data-qa": "complete-user-account-form" },
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--accent _mb-1",
                },
                [_vm._v(_vm._s(_vm.Resources.Account.CompleteAccountHeader))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-subheading gfu-subheading--bold _tac" },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.Account.CompleteAccountIntroParagraph)
                  ),
                ]
              ),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  name: "model.fullName",
                  type: "text",
                  "display-name":
                    _vm.Resources.Account.CompleteAccountFullNameFieldLabel,
                  "data-qa": "complete-user-account:NameField",
                },
                model: {
                  value: _vm.model.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "fullName", $$v)
                  },
                  expression: "model.fullName",
                },
              }),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  name: "model.nickname",
                  type: "text",
                  "display-name":
                    _vm.Resources.Account.CompleteAccountNicknameFieldLabel,
                  "data-qa": "complete-user-account:NicknameField",
                },
                model: {
                  value: _vm.model.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "nickname", $$v)
                  },
                  expression: "model.nickname",
                },
              }),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  value: _vm.email,
                  name: "model.email",
                  type: "text",
                  "read-only": true,
                  "display-name":
                    _vm.Resources.Account.CompleteAccountEmailFieldLabel,
                  "data-qa": "complete-user-account:EmailField",
                },
              }),
              _vm._v(" "),
              _c(
                "field",
                {
                  staticClass: "_mb-3",
                  attrs: {
                    name: "model.password",
                    "display-name":
                      _vm.Resources.Account.CompleteAccountPasswordFieldLabel,
                    "data-qa": "complete-user-account:PasswordField",
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.password,
                        expression: "model.password",
                      },
                    ],
                    staticClass: "gfu-field__input",
                    attrs: {
                      type: "password",
                      "data-qa": "complete-user-account:PasswordInput",
                    },
                    domProps: { value: _vm.model.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.model, "password", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "checkbox-field",
                {
                  staticClass: "_mt-3",
                  attrs: {
                    name: "model.subscribeToNewsletter",
                    condensed: true,
                    "data-qa": "complete-user-account:NewsletterCb",
                  },
                  model: {
                    value: _vm.model.subscribeToNewsletter,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "subscribeToNewsletter", $$v)
                    },
                    expression: "model.subscribeToNewsletter",
                  },
                },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.Account
                            .CompleteAccountNewsletterConsentLabel,
                      },
                    },
                    [
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            "format-key": "0",
                            text: _vm.Resources.Account
                              .CompleteAccountNewsletterTooltipText,
                          },
                        },
                        [
                          _c("em", { staticClass: "_tc--primary" }, [
                            _c("span", { staticClass: "_fa _fa--info" }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "checkbox-field",
                {
                  staticClass: "_mt-1",
                  attrs: {
                    name: "model.termsAccepted",
                    "display-name":
                      _vm.Resources.Account
                        .CompleteAccountTermsOfServiceFieldName,
                    condensed: true,
                    "data-qa": "complete-user-account:TermsCb",
                  },
                  model: {
                    value: _vm.model.termsAccepted,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "termsAccepted", $$v)
                    },
                    expression: "model.termsAccepted",
                  },
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "localize",
                        {
                          attrs: {
                            resource:
                              _vm.Resources.Account
                                .CompleteAccountTermsConsentLabel,
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: {
                                "format-key": "0",
                                href: _vm.termsAndConditionsUrl,
                                target: "_blank",
                                rel: "noopener noreferrer",
                                "data-qa": "complete-user-account:TosLink",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.Account.CompleteAccountTosLink
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: {
                                "format-key": "1",
                                href: _vm.privacyPolicyUrl,
                                target: "_blank",
                                "data-qa":
                                  "complete-user-account:PrivacyPolicyLink",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.Account
                                    .CompleteAccountPrivacyPolicyLink
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("field", { staticClass: "_mt-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "gfu-btn gfu-btn--accent gfu-btn--block",
                    attrs: {
                      type: "button",
                      disabled: _vm.hasErrors,
                      "data-qa": "complete-user-account:ContinueBtn",
                    },
                    on: { click: _vm.submit },
                  },
                  [
                    _c("span", { staticClass: "_pl-6 _pr-6" }, [
                      _vm._v(
                        _vm._s(_vm.Resources.Account.ContinueActionButton)
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }