
// source: https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/utils/browserInfo.ts
export function collectBrowserInfo() {
    const colorDepth = window.screen.colorDepth;
    const javaEnabled = window.navigator.javaEnabled ? window.navigator.javaEnabled() : false;
    const screenHeight = window.screen.height;
    const screenWidth = window.screen.width;
    const userAgent = window.navigator.userAgent;

    // IE <+ 10 supports navigator.browserLanguage instead of navigator.language
    const language = window.navigator.language || window.navigator.browserLanguage;

    const d = new Date();
    const timeZoneOffset = d.getTimezoneOffset();

    return {
        acceptHeader: '*/*',
        colorDepth,
        language,
        javaEnabled,
        screenHeight,
        screenWidth,
        userAgent,
        timeZoneOffset,
    };
}

export const cardBrands = {
    MasterCard: 'mc',
    Visa: 'visa',
    Amex: 'amex',
    Maestro: 'maestro',
    Diners: 'diners',
    JCB: 'jcb',
    Discover: 'discover',
    Cartebancaire: 'cartebancaire',
};

export const defaultCardNetworks = [
    cardBrands.MasterCard,
    cardBrands.Visa,
    cardBrands.Amex,
    cardBrands.Maestro,
    cardBrands.Diners,
    cardBrands.JCB,
    cardBrands.Discover,
    cardBrands.Cartebancaire,
];
