/* eslint-disable no-misleading-character-class */
import axios from 'axios';
import VeeValidate from 'vee-validate';
import { apiClient } from '@/Clients/ApiClient';

import Config from '../Config';
import LocationsService from '@/Services/LocationsService';
import UsersService from '@/Services/UsersService';

import { isNullOrWhitespace } from '@/Utils/TextUtility';
import { secureFieldIncorrectValue } from '@/Components/Payments/PaymentUtils';
import Resources from '@/Resources.js';

const foreignLanguageCharactersPatterns = {
    en: /^[0-9A-Z\.,_'\-\/\s]*$/i,
    cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ\.,_'\-\/\s]*$/i,
    da: /^[0-9A-ZÆØÅ\.,_'\-\/\s]*$/i,
    de: /^[0-9A-ZÄÖÜß\.,_'\-\/\s]*$/i,
    es: /^[0-9A-ZÁÉÍÑÓÚÜ\.,_'\-\/\s]*$/i,
    fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\.,_'\-\/\s]*$/i,
    lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ\.,_'\-\/\s]*$/i,
    hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ\.,_'\-\/\s]*$/i,
    nl: /^[0-9A-ZÉËÏÓÖÜ\.,_'\-\/\s]*$/i,
    pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ\.,_'\-\/\s]*$/i,
    pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ\.,_'\-\/\s]*$/i,
    ru: /^[0-9А-ЯЁ\.,_'\-\/\s]*$/i,
    se: /^[0-9A-ZÅÄÖ\.,_'\-\/\s]*$/i,
    sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ\.,_'\-\/\s]*$/i,
    sr: /^[0-9A-ZČĆŽŠĐ\.,_'\-\/\s]*$/i,
    tr: /^[0-9A-ZÇĞİıÖŞÜ\.,_'\-\/\s]*$/i,
    uk: /^[0-9А-ЩЬЮЯЄІЇҐ\.,_'\-\/\s]*$/i,
    ar: /^[\.,_'\-\/\s٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]*$/,
};

const alphaNumDashSpacesPattern = /^[0-9A-Z\.,_'\-\/\s]*$/i;
const anyAlphaNumDashSpacesPattern = /[0-9A-Z\.,_'\-\/\s]/ig;
const asciiStringPattern = /^[ -~\r\n]*$/i;
const anyAsciiCharacterPattern = /[ -~\r\n]/ig;

const dictionary = {
    messages: {
        required: (...[fieldName, ref]) => {
            return ref[0] && String(ref[0]) === ref[0]
                ? ref[0]
                : Resources.Common.ValidationFieldRequired;
        },
        max: (...[, ref]) => {
            const length = ref[0];
            return Resources.format(Resources.Common.ValidationMaxLength, length);
        },
        max_value: (...[fieldName, params]) => {
            const minValue = params[0];
            return Resources.format(Resources.Common.ValidationMaxValue, fieldName, minValue);
        },
        min: (...[fieldName, params]) => {
            const minLength = params[0];
            return Resources.format(Resources.Common.ValidationMinLength, fieldName, minLength);
        },
        min_value: (...[fieldName, params]) => {
            const minValue = params[0];
            return Resources.format(Resources.Common.ValidationMinValue, fieldName, minValue);
        },
        length(...[, ref]) {
            const length = ref[0];
            const max = ref[1];

            if (max && max !== length) {
                return `Please enter between ${length} and ${max} characters.`;
            }
            return `Please enter ${length} ${length !== 1 ? 'characters' : 'character'}.`;
        },
        alpha_dash: (...[fieldName]) => {
            return Resources.format(Resources.Common.ValidationAlphaDash, fieldName);
        },
        email: (...[fieldName]) => {
            return Resources.format(Resources.Common.ValidationEmail, fieldName);
        },
    },
};

VeeValidate.Validator.localize(Config.language.currentLanguageIsoCode, dictionary);

VeeValidate.Validator.extend('ascii', {
    getMessage: (...[, args]) => {
        return 'This field contains invalid characters.';
    },
    validate: (value, ref) => {
        return asciiStringPattern.test(value);
    },
});

VeeValidate.Validator.extend('alpha_num_dash_spaces', {
    getMessage: (...[, args]) => {
        return 'This field contains invalid characters.';
    },
    validate: (value, ref) => {
        return alphaNumDashSpacesPattern.test(value);
    },
});

VeeValidate.Validator.extend('human_name', {
    getMessage: (...[, args]) => {
        return 'This field contains invalid characters.';
    },
    validate: (value, ref) => {
        const nonalphaNumDashSpacesString = value.replace(anyAlphaNumDashSpacesPattern, '');
        if (nonalphaNumDashSpacesString.length === 0) {
            return true;
        }

        const result = Object.values(foreignLanguageCharactersPatterns).some(regexp => regexp.test(nonalphaNumDashSpacesString));

        return result;
    },
});

VeeValidate.Validator.extend('shipping_data', {
    getMessage: (...[, args]) => {
        return 'This field contains invalid characters.';
    },
    validate: (value, ref) => {
        const nonAsciiString = value.replace(anyAsciiCharacterPattern, '');
        if (nonAsciiString.length === 0) {
            return true;
        }

        const result = Object.values(foreignLanguageCharactersPatterns).some(regexp => regexp.test(nonAsciiString));

        return result;
    },
});

VeeValidate.Validator.extend('max_date_validator', {
    getMessage: (...[, args]) => `Date cannot be greater than ${args[0].toLocaleDateString()}`,
    validate: (value, args) => {
        if (value) {
            if (args[0] === null) {
                return Promise.resolve(true);
            }
            const date = new Date(value).setHours(0, 0, 0, 0);
            const maxDate = new Date(args[0]).setHours(0, 0, 0, 0);
            if (date <= maxDate) {
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('max_date_time_validator', {
    getMessage: (...[, args]) => `Date cannot be greater than ${args[0].toLocaleDateString()} ${args[0].toLocaleTimeString()}`,
    validate: (value, args) => {
        if (value) {
            if (args[0] === null) {
                return Promise.resolve(true);
            }
            const date = value;
            const maxDate = args[0];
            if (date <= maxDate) {
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('min_date_validator', {
    getMessage: (...[, args]) => `Date cannot be lower than ${args[0].toLocaleDateString()}`,
    validate: (value, args) => {
        if (value) {
            if (args[0] === null) {
                return Promise.resolve(true);
            }
            const date = new Date(value).setHours(0, 0, 0, 0);
            const minDate = new Date(args[0]).setHours(0, 0, 0, 0);
            if (date >= minDate) {
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('min_date_time_validator', {
    getMessage: (...[, args]) => `Date cannot be lower than ${args[0].toLocaleDateString()} ${args[0].toLocaleTimeString()}`,
    validate: (value, args) => {
        if (value) {
            if (args[0] === null) {
                return Promise.resolve(true);
            }
            const date = new Date(value);
            const minDate = new Date(args[0]);
            if (date >= minDate) {
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    },
});

// Phone number validator
VeeValidate.Validator.extend('phone_number', {
    getMessage: (field, params, data) => {
        return 'This field contains invalid characters.';
    },
    validate: (value, ref) => {
        const pattern = /^[0-9\.\+\-\s]*$/i;

        return pattern.test(value);
    },
});

// VAT number validator
VeeValidate.Validator.extend('vat_number', {
    getMessage(field, args) {
        return 'This VAT number does not list as valid in the EU VAT Information Exchange System.';
    },
    validate(value, args) {
        if (!args[0]) {
            throw 'Missing projectID argument in VAT number validator rule.';
        }
        if (!args[1]) {
            throw 'Missing project locationID argument in VAT number validator rule.';
        }

        if (value && value.trim().length > 0) {
            const http = axios.create({
                baseURL: Config.http.root,
            });

            return new LocationsService(http).validateVatNumber(args[0], args[1], value).
                then(response => {
                    return Promise.resolve(response.data.success);
                }, () => {
                    return Promise.reject(false);
                });
        }

        return Promise.resolve(false);
    },
});

// VAT number validator
// ? not really;D
VeeValidate.Validator.extend('unique_max_weight', {
    getMessage(field, args) {
        return 'Duplicated value.';
    },
    validate(value, args) {
        if (!Number.isInteger(args[0])) {
            throw 'Missing current weight range ID.';
        }
        if (!Array.isArray(args[1])) {
            throw 'Missing weight ranges argument.';
        }

        if (value) {
            return Promise.resolve(!args[1].find(r => r.shippingWeightRangeID !== args[0] && r.maxWeight === value));
        }
        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('not_null_or_empty', {
    getMessage(field, args) {
        return 'Text cannot be empty or whitespace.';
    },
    validate(value, args) {
        return !isNullOrWhitespace(value);
    },
});

VeeValidate.Validator.extend('file_type', {
    getMessage(field, args) {
        return `We could not recognize the format of your file. Accepted formats are ${args.join(', ')}.`;
    },
    validate(value, args) {
        if (!args.length > 0) {
            throw 'Missing file types.';
        }
        const fileType = value?.fileName
            ? value.fileName.split('.').pop()
                .toLowerCase()
            : null;
        return [...args, null].includes(fileType);
    },
});

VeeValidate.Validator.extend('file_size', {
    getMessage(field, args) {
        return `Your file is too large! Maximum allowed size is ${args[0]}MB.`;
    },
    validate(value, args) {
        const maxFileSize = args[0];
        const filesSizeInMB = value.size / 1024 / 1024;

        if (!Number.isInteger(maxFileSize)) {
            throw 'Missing file maximum size in MB.';
        }

        return filesSizeInMB <= maxFileSize;
    },
});

VeeValidate.Validator.extend('onboarding_file_size', {
    getMessage() {
        return 'File size is incorrect. Minimum file size is 1 KB for PDFs or 100 KB for other file formats. Maximum file size is 4 MB.';
    },
    validate(value) {
        if (!value.content) {
            return true;
        }

        const minFileSizeInKB = value.contentType === 'application/pdf'
            ? 1
            : 100;
        const maxFileSizeInMB = 4;
        const filesSize = value.size;

        return filesSize >= minFileSizeInKB * 1024 && filesSize <= maxFileSizeInMB * 1024 * 1024;
    },
});

VeeValidate.Validator.extend('onboarding_phone_number', {
    getMessage: (field, params, data) => {
        return 'The phone number field should only consist of numbers and the plus (+) character.';
    },
    validate: (value, ref) => {
        const pattern = /^\+[0-9]*$/i;

        return pattern.test(value);
    },
});

VeeValidate.Validator.extend('onboarding_human_name', {
    getMessage: (...[, args]) => {
        return 'This field contains invalid characters. Digits (0 - 9) and underscore (_) are not allowed.';
    },
    validate: (value) => {
        const pattern = /^[^\d_]*$/i;

        return pattern.test(value);
    },
});

// Rich text length validator
VeeValidate.Validator.extend('rich_text_length', {
    getMessage(field, args) {
        return `The text is longer than ${args[1]} characters.`;
    },
    validate(value, args) {
        if (!args[0]) {
            throw 'Missing rich text field.';
        }

        if (!Number.isInteger(args[1])) {
            throw 'Missing max length.';
        }

        if (value) {
            const richTextEditor = args[0].$children[0].$children.find(c => c.$attrs['validator-selector'] === 'richTextEditor');
            return Promise.resolve(richTextEditor.textLength <= args[1]);
        }

        return Promise.resolve(false);
    },
});

// Required checkbox validator
VeeValidate.Validator.extend('required_checkbox', {
    getMessage(field, args) {
        return Resources.format(Resources.Common.CheckboxRequiredValidationMessage, field);
    },
    validate(value, args) {
        return Promise.resolve(value);
    },
});

const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

VeeValidate.Validator.extend('entity_name', {
    getMessage(field, args) {
        return 'This field contains invalid characters.';
    },
    validate(value, args) {
        return !emojiRegex.test(String(value));
    },
});

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const facebookProfileLinkRegex = /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.(me|com))\/([\w\-\.=]+[\/\?&]?)+$/;
const instagramProfileLinkRegex = /^(https?:\/\/)?(www\.)?(instagram\.com|instagr\.am)\/([\w\-\.=]+[\/\?&]?)+$/;
const twitterProfileLinkRegex = /^(?:(?:https?):\/\/)?(?:www\.)?(?:(?:twitter)|x)\.com\/([A-Za-z0-9-_\\./]+)/;
const youtubeProfileLinkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/@?[\w/-]+/;

// Override email validator
// This is email validator from latest vee-validate. Our current (older version) validator marks good emails as invalid (long emails), and bad emails as valid (e.g. unicode emails)
VeeValidate.Validator.extend('email', {
    getMessage(field, args) {
        return Resources.format(Resources.Common.ValidationEmail, field);
    },
    validate(value, [options, ...args]) {
        const re = emailRegex;
        if (options && options.multiple && !Array.isArray(value)) {
            value = String(value)
                .split(',')
                .map(emailStr => emailStr.trim());
        }

        if (Array.isArray(value)) {
            return value.every(val => re.test(String(val)));
        }

        return re.test(String(value));
    },
});

VeeValidate.Validator.extend('digits_length', {
    getMessage(...[, ref]) {
        const length = ref[0];
        const max = ref[1];

        if (max && max !== length) {
            return `Please enter between ${length} and ${max} digits.`;
        }
        return `Please enter ${length} ${length !== 1 ? 'digits' : 'digit'}.`;
    },
    validate(value, ref) {
        if (value === undefined
            || value === null
            || isNaN(value)) {
            return false;
        }
        const length = Number(ref[0]);
        const max = Number(ref[1]);
        const currentLength = value.length;

        if (max > 0 && currentLength > max) {
            return currentLength >= length;
        }

        return currentLength === length;
    },
});

// Global function validation with message.
VeeValidate.Validator.extend('function', {
    getMessage(value, [options, ...args]) {
        if (options?.message) {
            return options.message;
        }

        return 'Sorry, your input was invalid.';
    },
    validate(value, [options, ...args]) {
        if (typeof options.function === 'function') {
            return options.function(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('communicationUrlValidator', {
    getMessage(field, args) {
        return `The ${field} is not valid.`;
    },
    validate(value, args) {
        if (value) {
            const domain = args[0].length ? args[0] : '.*[A-Za-z0-9]{2,}';
            const regex = new RegExp(`^(https?:\/\/)?((w{3}\\.)?)${domain}\\..*(.*[A-Za-z0-9]){2,}`, 'i');
            return Promise.resolve(regex.test(value));
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('facebookUrl', {
    getMessage() {
        return Resources.AccountSettings.ValidationInvalidSocialLink;
    },
    validate(value) {
        if (value) {
            return facebookProfileLinkRegex.test(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('instagramUrl', {
    getMessage(field) {
        return Resources.AccountSettings.ValidationInvalidSocialLink;
    },
    validate(value) {
        if (value) {
            return instagramProfileLinkRegex.test(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('twitterUrl', {
    getMessage(field) {
        return Resources.AccountSettings.ValidationInvalidSocialLink;
    },
    validate(value) {
        if (value) {
            return twitterProfileLinkRegex.test(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('youtubeUrl', {
    getMessage(field) {
        return Resources.AccountSettings.ValidationInvalidSocialLink;
    },
    validate(value) {
        if (value) {
            return youtubeProfileLinkRegex.test(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('secureFieldValidator', {
    getMessage: (field, [secureFieldErrors, fieldName] = {}) => {
        return secureFieldErrors[fieldName] || Resources.Common.IncompleteFieldErrorMessage;
    },
    validate: (value, [secureFieldErrors, fieldName] = {}) => {
        // return invalid when there is any error for given field or the value is equal to secureFieldIncorrectValue
        return !(secureFieldErrors[fieldName] || value === secureFieldIncorrectValue);
    },
});

VeeValidate.Validator.extend('nicknameAvailabilityValidator', {
    getMessage(field, args) {
        return Resources.AccountSettings.ApiNicknameNotAvailableError;
    },
    validate(value, args) {
        if (value) {
            const usersService = new UsersService(apiClient);
            return usersService.isNicknameAvailable(value).
                then(response => Promise.resolve(response.data))
                .catch(error => Promise.reject(false));
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('nicknameAlphaDashValidator', {
    getMessage(field, args) {
        return Resources.format(Resources.Common.ValidationAlphaDash, field);
    },
    validate(value, args) {
        if (value) {
            // regular alpha_dash allows also diacritics and non-latin characters
            const regex = /^[A-Za-z0-9_-]+$/;
            return regex.test(value);
        }

        return Promise.resolve(false);
    },
});

VeeValidate.Validator.extend('hexColor', {
    getMessage(field, args) {
        return 'This color value is not valid.';
    },
    validate(value, args) {
        if (value) {
            const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
            return regex.test(value);
        }

        return false;
    },
});

VeeValidate.Validator.extend('matchPasswords', {
    getMessage(field, args) {
        return Resources.AccountSettings.ChangePasswordPasswordsDontMatchError;
    },
    validate(value, args) {
        return value === args[0];
    },
});

export function registerUrlAvailabilityValidator(validationFn) {
    VeeValidate.Validator.extend('url_available', {
        getMessage: () => 'This address is already taken.',
        validate: validationFn,
    });
}

export function registerProjectNameValidator() {
    VeeValidate.Validator.extend('project_name', {
        getMessage(field) {
            return `This ${field} is reserved.`;
        },
        validate(value) {
            const regex = new RegExp('^(?!store$|^.*\\bstore\\b.*$|^store\\b.*$|.*\\bstore$).*$', 'i');
            return regex.test(String(value));
        },
    });
}

export function registerProjectUrlValidator() {
    VeeValidate.Validator.extend('project_url', {
        getMessage(field) {
            return `This ${field} is reserved.`;
        },
        validate(value) {
            const regex = new RegExp('^(?!store$|store-|.*-store$|.*-store-).*$', 'i');
            return regex.test(String(value));
        },
    });
}