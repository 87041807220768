import { paymentStatuses } from '@/Models';

export const orderPaymentStatuses = {
    [paymentStatuses.deferredWithError]: 'Payment error',
    [paymentStatuses.canceled]: 'Cancelled',
    [paymentStatuses.completed]: 'Paid',
    [paymentStatuses.deferred]: 'Scheduled',
};

export const installmentsPaymentStatuses = {
    ...orderPaymentStatuses,
    [paymentStatuses.canceled]: 'Not applicable',
};