import Formatter from '@/Utils/Formatter';
import { installmentsPaymentStatuses } from '@/Utils/PaymentUtility';
import { paymentStatuses } from '@/Models';
import Resources from '@/Resources.js';

export function buildInstallmentPlanModel(installmentDetails, currentDisplayCurrency, currencySelected, isDccEnabled) {
    const currencySymbol = installmentDetails.currencySymbol;

    const hasPaidInstallment = installmentDetails.status === paymentStatuses.completed;
    const statusColor = {
        [paymentStatuses.deferredWithError]: 'invalid',
        [paymentStatuses.completed]: 'accented',
        [paymentStatuses.canceled]: '',
    };

    const showApproximateValue = [
        !!currentDisplayCurrency,
        !hasPaidInstallment,
        !currencySelected,
        !isDccEnabled,
    ].every(v => v);

    const amountToPayDisplay = installmentDetails.amountToPayConverted
        ? installmentDetails.amountToPayConverted
        : installmentDetails.amountToPay;

    const currencySymbolDisplay = currencySelected ? currencySelected.symbol : currencySymbol;

    const installmentPlanModel = [
        {
            condition: !!installmentDetails.status,
            title: Resources.YourPledge.InstallmentPlanStatusLabel,
            value: installmentsPaymentStatuses[installmentDetails.status],
            theme: statusColor[installmentDetails.status],
        },
        {
            condition: true,
            title: Resources.YourPledge.InstallmentPlanPaymentDateLabel,
            value: Formatter.format(installmentDetails.paymentDate, 'd'),
        },
        {
            condition: true,
            title: Resources.YourPledge.InstallmentPlanAmountLabel,
            value: Formatter.format(amountToPayDisplay, 'C2', currencySymbolDisplay),
        },
        {
            condition: showApproximateValue,
            title: Resources.YourPledge.InstallmentPlanApproximateConversionLabel,
            tooltipText: Resources.YourPledge.InstallmentPlanApproximateConversionTooltip,
            value: Formatter.format(installmentDetails.amountToPay / currentDisplayCurrency?.relativeFactorWithCommission, 'C2', currentDisplayCurrency?.symbol),
        },
        {
            condition: hasPaidInstallment,
            title: 'divider',
            isDivider: true,
        },
        {
            condition: hasPaidInstallment,
            title: Resources.YourPledge.InstallmentPlanTotalPaidLabel,
            value: Formatter.format(amountToPayDisplay, 'C2', currencySymbolDisplay),
        },
    ];

    return installmentPlanModel.filter(installmentPlanModelItem => {
        if (installmentPlanModelItem.condition) {
            delete installmentPlanModelItem.condition;
            return installmentPlanModelItem;
        }
        return;
    });
}